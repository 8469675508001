import firebase from 'firebase/app';
import 'firebase/storage';
import * as uuid from 'uuid/v4';
import filesize from 'filesize';
// const webp = require('webp-converter');

// this will grant 755 permission to webp executables
// webp.grant_permission();

function extractExtensionFrom(filename) {
  if (!filename) {
    return null;
  }

  const regex = /(?:\.([^.]+))?$/;
  return regex.exec(filename)[1];
}

export class FileUploader {
  static validate(file, schema) {
    if (!schema) {
      return;
    }
    if (schema.image) {
      if (!file.type.startsWith('image')) {
        throw new Error('fileUploader.image');
      }
    }

    if (schema.size && file.size > schema.size) {
      throw new Error(
        'fileUploader.size',
        filesize(schema.size),
      );
    }

    const extension = extractExtensionFrom(file.name);

    if (
      schema.formats &&
      !schema.formats.includes(extension)
    ) {
      throw new Error(
        'fileUploader.formats',
        schema.formats.join('/'),
      );
    }
  }

  static uploadFromRequest(path, request, schema) {
    try {
      FileUploader.validate(request.file, schema);
    } catch (error) {
      request.onError(error);
      console.log(error);
      return;
    }

    const ref = firebase.storage().ref();
    const extension = extractExtensionFrom(request.name);
    const id = uuid();
    const fullPath = `${path}/${id}.${extension}`;
    const task = ref.child(fullPath).put(request);

    task.on(
      //   console.log('1'),
      firebase.storage.TaskEvent.STATE_CHANGED,
      () => {},

      (error) => {
        console.log(error);
      },
      async () => {
        await task.snapshot.ref
          .getDownloadURL()
          .then((url) => {
            return url;
          })
          .catch((error) => {
            console.log(error);
          });
      },
    );
  }
  //   eslint-disable-next-line
  static async uploadFile(path, request) {
    var uploads;
    const promises = [];
    const ref = firebase.storage().ref();
    const file = request;
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    const fullPath = `${path}/${timestamp}`;
    const task = ref.child(fullPath);
    const uploadPromise = task.put(file).then(async () => {
      const downloadURL = await task.getDownloadURL();
      uploads = {
        name: file.name,
        publicUrl: downloadURL,
      };
    });
    promises.push(uploadPromise);
    await Promise.all(promises);
    console.log(uploads);
    return uploads;
  }
}
